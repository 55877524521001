import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import { cx } from "class-variance-authority";
import { type ButtonHTMLAttributes, forwardRef } from "react";

const buttonVariants = cva(
    "typography-sub remove-styles-a flex cursor-pointer items-center justify-center rounded border border-solid text-center outline-none transition active:shadow-none disabled:cursor-not-allowed disabled:shadow-none",
    {
        variants: {
            variant: {
                primary:
                    "border-transparent bg-primary text-white hover:text-white hover:shadow-md focus:border-primary-700 active:border-transparent active:bg-primary-800 disabled:bg-blue-grey-50 disabled:text-blue-grey-200",
                secondary:
                    "border-transparent bg-primary-50 text-primary-600 hover:bg-primary-100 hover:text-primary-600 hover:shadow-md active:bg-primary-100 disabled:bg-primary-50 disabled:text-primary-300",
                tertiary:
                    "border border-solid border-blue-grey-100 bg-white text-blue-grey-700 hover:border-blue-grey-200 hover:text-blue-grey-700 hover:shadow-md focus:border-blue-grey-200 focus:bg-blue-grey-50 active:border-transparent active:focus:bg-primary-100 disabled:text-blue-grey-200 disabled:hover:border-blue-grey-100",
                outlined:
                    "border border-solid border-primary bg-transparent text-primary hover:text-primary-600 focus:border-primary-800 focus:bg-primary-50 focus:text-primary-600 active:border-primary active:bg-primary-100 active:text-primary-600 disabled:border-primary-300 disabled:text-primary-200",
                text: "border-transparent bg-transparent text-primary hover:font-bold hover:text-primary focus:border-primary-800 focus:text-primary active:border-transparent active:font-bold active:focus:text-primary-700 disabled:font-medium disabled:text-primary-300",
                destructivePrimary:
                    "border-transparent bg-destructive text-white hover:bg-destructive-600 hover:text-white hover:shadow-md focus:border-destructive-800 focus:bg-destructive-600 active:border-transparent active:bg-destructive-600 active:text-destructive-200 disabled:bg-destructive-300 disabled:hover:bg-destructive-300",
                destructiveSecondary:
                    "border-transparent bg-destructive-50 text-destructive hover:bg-destructive-100 hover:text-destructive hover:text-destructive-600 hover:shadow-md focus:border-destructive-800 focus:bg-destructive-100 active:border-transparent active:bg-destructive-100 active:text-destructive active:focus:text-destructive",
                destructiveTertiary:
                    "border border-solid border-destructive-200 bg-white text-destructive hover:text-destructive hover:shadow-md focus:border-destructive-800 focus:bg-destructive-50 active:border-destructive-300 active:bg-destructive-100 active:focus:bg-destructive-100 disabled:border-destructive-200 disabled:bg-white disabled:text-destructive-300 disabled:hover:border-destructive-200 disabled:hover:bg-white lg:hover:border-destructive-300 lg:hover:bg-destructive-50",
                destructiveOutlined:
                    "border border-solid border-destructive-400 bg-transparent text-destructive hover:text-destructive focus:border-destructive-800 focus:text-destructive-600 active:border-destructive active:font-bold active:text-destructive disabled:border-destructive-300 disabled:text-destructive-300 disabled:hover:border-destructive-300 disabled:hover:text-destructive-300 lg:hover:border-destructive lg:hover:text-destructive-600",
                destructiveText:
                    "border-transparent bg-transparent text-destructive hover:font-bold hover:text-destructive focus:border-destructive-800 focus:text-destructive-600 active:border-transparent active:font-bold active:text-destructive-700 disabled:text-destructive-300 disabled:hover:font-medium",
            },
            size: {
                zero: "",
                lg: "h-11 py-3",
                md: "h-10 py-2.5",
                sm: "h-[29px] py-1.5",
            },
        },
        defaultVariants: {
            variant: "primary",
            size: "md",
        },
    },
);

export interface ButtonProps
    extends ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, variant, size, asChild = false, ...props }, ref) => {
        const Comp = asChild ? Slot : "button";
        return (
            <Comp
                className={cx(buttonVariants({ variant, size, className }))}
                ref={ref}
                {...props}
            />
        );
    },
);
Button.displayName = "Button";

export { Button, buttonVariants };
