import { LogomarkWithNameBlue } from "@/assets/vectors/logo";
import { DesktopFooter } from "@/components/DesktopFooter";
import {
    DesktopHeaderNoProfile,
    MobileHeaderNoProfile,
} from "@/components/Header";
import { UserMenu } from "@/components/UserMenu";
import { auth } from "@/lib/auth/auth";
import { useUser } from "@/lib/auth/useUser";
import { signOut } from "firebase/auth";
import Link from "next/link";
import type { ReactNode } from "react";

const HeaderLayout = ({
    mobileTitle,
    children,
}: {
    mobileTitle?: ReactNode;
    children: ReactNode;
}): JSX.Element => (
    <>
        <DesktopHeaderNoProfile />
        <MobileHeaderNoProfile>{mobileTitle}</MobileHeaderNoProfile>
        <main className="min-h-screen">{children}</main>
        <DesktopFooter />
    </>
);

const HeaderLayoutWithProfile = (props: {
    children?: ReactNode;
}): JSX.Element => {
    const user = useUser();
    return (
        <div className="flex min-h-screen flex-col">
            <header className="sticky top-0 z-10 border-0 border-b border-solid border-blue-grey-50 bg-white p-3 shadow-lg">
                {/* 
                    The `container mx-auto` classes are mainly to
                    match the /organisation page styling. Please
                    change it if this is to be used for other places
                */}
                <div className="container mx-auto flex items-center justify-between">
                    <Link href="/organisation">
                        <a className="flex">
                            <LogomarkWithNameBlue />
                        </a>
                    </Link>
                    {!!user && (
                        <UserMenu user={user} onLogout={() => signOut(auth)} />
                    )}
                </div>
            </header>
            <main className="flex-1">{props.children}</main>
            <DesktopFooter />
        </div>
    );
};

export { HeaderLayout, HeaderLayoutWithProfile };
